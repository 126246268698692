import {
  connectQuery,
  createMutation,
  createQuery,
  update,
} from '@farfetched/core';
import { combine, createEffect } from 'effector';

import { atom } from '@kuna-pay/utils/misc';
import type { CoreAsset } from '@kuna-pay/core/entities/asset';
import { AssetSchema } from '@kuna-pay/core/entities/asset';

import { LocalStoragePersistKeys } from '@kuna-pay/merchant/shared/persist';

import { EUR_ASSET } from '../../asset';
import { $$merchantQuery } from './queries/company.query';

/**
 * Depends on `$$getCompanyQuery.$data` so
 * it should be used only inside "company" context
 *
 */
const $$referenceCurrency = atom(() => {
  const $companyReferenceCurrencyAsset = combine(
    $$merchantQuery.$data,
    (merchant) => merchant?.ReferenceCurrencyAsset ?? null!
  );

  const $$localReferenceCurrencyAssetQuery = createQuery({
    name: 'session/queries/local-ref-currency',
    effect: createEffect((companyId: string): null | CoreAsset =>
      new RefCurrencyPersistor(companyId).get()
    ),
  });

  const $$updateLocalReferenceCurrencyMutation = createMutation({
    name: 'session/mutations/update-local-ref-currency',
    effect: createEffect(
      ({ companyId, asset }: { companyId: string; asset: CoreAsset }) => {
        new RefCurrencyPersistor(companyId).set(asset);
      }
    ),
  });

  update($$localReferenceCurrencyAssetQuery, {
    on: $$updateLocalReferenceCurrencyMutation,
    by: {
      success: ({ mutation }) => ({
        result: mutation.params.asset,
        refetch: true,
      }),
    },
  });

  connectQuery({
    source: $$merchantQuery,
    fn: ({ result }) => ({ params: result.id }),
    target: $$localReferenceCurrencyAssetQuery,
  });

  return {
    $asset: combine(
      $$localReferenceCurrencyAssetQuery.$data,
      $companyReferenceCurrencyAsset,
      (localAsset, companyRefCurrency): CoreAsset =>
        localAsset ?? companyRefCurrency ?? EUR_ASSET
    ),
    $$localReferenceCurrencyAssetQuery,
    $$updateLocalReferenceCurrencyMutation,
  };
});

class RefCurrencyPersistor {
  public constructor(companyId: string) {
    this.key = `${LocalStoragePersistKeys.RefCurrency}:${companyId}`;
  }

  private readonly key: string;

  public get(): null | CoreAsset {
    const value = localStorage.getItem(this.key);

    if (!value) return null;

    return AssetSchema.parse(JSON.parse(value));
  }

  public set(asset: CoreAsset) {
    const serialized = JSON.stringify(asset);

    localStorage.setItem(this.key, serialized);
  }
}

export { $$referenceCurrency };
