import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import type { ButtonProps } from '@kuna-pay/ui/ui/button';
import type { FormProps } from '@kuna-pay/form';
import { Field, Form, useField, useFormError } from '@kuna-pay/form';
import { useTypedGate } from '@kuna-pay/core/shared/router';

import { VerifyOneTimeCodeModel } from './verify-one-time-code.model';
import styles from './verify-one-time-code.module.scss';

type VerifyOTPFormProps = PropsWithChildren & {
  name: FormProps['name'];

  className?: string;

  hideButton?: boolean;

  button?: Partial<ButtonProps>;

  defaultValue?: string | null;
};

const VerifyOneTimeCodeForm = modelView(
  VerifyOneTimeCodeModel,
  ({
    name,
    hideButton,
    className,
    children,
    button,
    defaultValue,
  }: VerifyOTPFormProps) => {
    const $$model = VerifyOneTimeCodeModel.useModel();
    const hasFormError = !!useFormError($$model.$$form);
    const i18n = useTranslation('core');
    useTypedGate({
      Gate: $$model.Gate,
      props: { i18n },
    });

    const { t } = i18n;

    const { value } = useField($$model.$$form.fields.otp);

    return (
      <Form name={name} className={className} use={$$model.$$form}>
        <Field.CodeField
          field={$$model.$$form.fields.otp}
          defaultValue={defaultValue}
        />

        <Form.Error
          as='p'
          className={clsx(styles.error, {
            [styles.hasError]: hasFormError,
            [styles.hasChildren]: !!children,
          })}
          opacity
        />

        {children}

        {!hideButton && (
          <Form.Submit
            variant='contained'
            color='primary'
            size='xl'
            disableIfNotValid
            fullWidth
            disabled={!value}
            {...(button ?? {})}
          >
            {t('features.auth.abstract.verify-one-time-code.ui.action')}
          </Form.Submit>
        )}
      </Form>
    );
  }
);

VerifyOneTimeCodeForm.displayName = 'VerifyOneTimeCodeForm';

export { VerifyOneTimeCodeForm };
export type { VerifyOTPFormProps };
