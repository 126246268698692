import { combine, createEvent } from 'effector';

import { getState, listen, setState } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';
import type { Feature } from '@kuna-pay/core/generated/public/graphql';
import { createEQuery } from '@kuna-pay/core/shared/lib/effector-query';

import { $$api } from '@kuna-pay/merchant/shared/api';
import { publicFeatures } from '@kuna-pay/merchant/shared/api/generated/Feature/request/publicFeatures';

import type { AvailableFeatureFlags } from '../feature-flags.types';
import { listToKV } from '../lib';
import { $$staticFeatures } from './static.model';

const $$featureFlags = atom(() => {
  const updateFeatureFlag = createEvent<{
    name: AvailableFeatureFlags;
    value: boolean;
  }>();

  const $$dynamicFeatures = createEQuery({
    initialData: [] as Feature[],

    query: async (_: void) => {
      const features = await publicFeatures({ name: true, value: true })();

      return features;
    },
  });

  listen({
    clock: $$api.tokenService.onTokenUpdated,
    handler: () => {
      void $$dynamicFeatures.refreshFx();
    },
  });

  listen({
    clock: $$api.tokenService.onTokenCleared,
    handler: () => {
      $$dynamicFeatures.reset();
    },
  });

  listen({
    clock: updateFeatureFlag,
    handler: async ({ name, value }) => {
      const features = await getState($$dynamicFeatures.$data);

      const feature = features.find((f) => f.name === name);

      if (feature) {
        setState(
          $$dynamicFeatures.$data,
          features.map((f) => (f.name === name ? { ...f, value } : f))
        );
      } else {
        setState($$dynamicFeatures.$data, [...features, { name, value }]);
      }
    },
  });

  return {
    loadStaticFeaturesFx: $$staticFeatures.startFx,
    loadDynamicFeaturesFx: $$dynamicFeatures.startFx,
    updateFeatureFlag,

    $features: combine(
      $$staticFeatures.$data,
      $$dynamicFeatures.$data,
      (nonAuth, auth) => ({ ...listToKV(nonAuth), ...listToKV(auth) })
    ),
    $dynamicFeatures: $$dynamicFeatures.$data,
    $staticFeatures: $$staticFeatures.$data,

    $$staticFeatures,
    $$dynamicFeatures,
  };
});

export { $$featureFlags };
