import { combine, createEvent, createStore } from 'effector';
import { persist } from 'effector-storage/session';
import type { Location } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import { string } from 'yup';

import { listen, setState } from '@kuna-pay/utils/effector';
import { atom, bridge } from '@kuna-pay/utils/misc';
import type { IAtomicRRRoute } from '@kuna-pay/ui/router';

import { $$api } from '@kuna-pay/merchant/shared/api';
import { SessionStoragePersistKeys } from '@kuna-pay/merchant/shared/persist';
import { routes } from '@kuna-pay/merchant/shared/router';

import { createSearchParamsCollector } from '../lib/search-params-collector';

const PERSIST_CONFIG = {
  PATH: 'REDIRECT_ON_SIGN_IN',
  COMPANY: 'AUTO_LOGIN_COMPANY_ID',
};

const URL_QUERY_PARAMS_CONFIG = {
  COMPANY_ID: 'company_id',
  OTP: 'otp',
};

const $$redirectOnSignIn = atom(() => {
  const $path = createStore<string | null>(null);

  const reset = createEvent();
  const savePath = createEvent<string>();

  listen({
    clock: savePath,
    handler: (path) => {
      if (shouldSaveForRedirect(path)) {
        setState($path, path);
      }
    },
  });

  // Save on logout from api
  // This happens when tokens are expired and not on page open
  listen({
    clock: $$api.tokenService.onLoggedOut,
    handler: () => {
      savePath(window.location.pathname);
    },
  });

  listen({
    clock: reset,
    handler: () => {
      $path.reinit!();
    },
  });

  bridge(() => {
    persist({ store: $path, key: PERSIST_CONFIG.PATH });
  });

  bridge(() => {
    $path.reset(reset);
  });

  return {
    $hasSavedRedirectPath: combine($path, (path) => Boolean(path)),

    useCompanyIdCollector: createSearchParamsCollector({
      searchParamKey: URL_QUERY_PARAMS_CONFIG.COMPANY_ID,
      storageKey: PERSIST_CONFIG.COMPANY,
      storage: sessionStorage,
    }),

    getCompanyId: async () => {
      const maybeCompanyId = sessionStorage.getItem(PERSIST_CONFIG.COMPANY);

      if (string().uuid().isValidSync(maybeCompanyId)) {
        return maybeCompanyId;
      }

      return null;
    },

    $path,

    savePath,
    reset,

    URL_QUERY_PARAMS_CONFIG,
  };
});

const $$otpParam = atom(() => {
  const reset = createEvent();

  listen({
    clock: reset,
    handler: () => {
      sessionStorage.removeItem(SessionStoragePersistKeys.OTP);
    },
  });

  return {
    useOtpCollector: createSearchParamsCollector({
      searchParamKey: URL_QUERY_PARAMS_CONFIG.OTP,
      storageKey: SessionStoragePersistKeys.OTP,
      storage: sessionStorage,
    }),

    getOtp: async () => {
      const maybeOtp = sessionStorage.getItem(SessionStoragePersistKeys.OTP);

      if (string().isValidSync(maybeOtp)) {
        return maybeOtp;
      }

      return null;
    },

    reset,
  };
});

const allowedRoutes: IAtomicRRRoute[] = [
  routes.dashboard,
  routes.assets.list,
  routes.transactions.list,
  routes.transactions.details,
  routes.transactions.processing,
  routes.invoices.list,
  routes.invoices.details,
  routes.members.list,
  routes.settings.account,
  routes.settings.apiKey,
  routes.settings.autoConversion,
  routes.settings.exchangeRate,
  routes.reporting.root,
  routes.reporting.settlement,
  routes.reporting.statement,
  routes.profile.main,
  routes.profile.security,
  routes.profile.notifications,
  routes.companyFees.root,
  routes.companyFees.deposit,
  routes.companyFees.invoiceDeposit,
  routes.companyFees.withdrawal,
  routes.companyFees.convert,
  routes.companyFees.payout,
  routes.referrals.list,
  routes.notifications.root,
];

function shouldSaveForRedirect(pathname: Location['pathname']) {
  const shouldSave = allowedRoutes.some((route) => {
    const match = matchPath(route, pathname);

    return !!match;
  });

  return shouldSave;
}

export { $$otpParam, $$redirectOnSignIn };
