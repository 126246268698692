import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { $$session } from '@kuna-pay/merchant/entities/session';

import { ReactComponent as OtpNotEnabledAlertIcon } from '../../../assets/otp-not-enabled-alert.svg';
import styles from './exceed-limits-of-individual-plan-alert.module.scss';

type ExceedLimitsOfIndividualPlanAlertProps = PropsWithChildren & {
  className?: string;

  classes?: {
    root?: string;
    content?: string;
    aside?: string;
    text?: string;
  };
};

const ExceedLimitsOfIndividualPlanAlert = createCompoundComponent(
  (Components) =>
    ({
      className,
      classes,
      children,
    }: ExceedLimitsOfIndividualPlanAlertProps) => {
      const { t } = useTranslation(undefined, {
        keyPrefix: 'entities.session.ui.exceed-limits-of-individual-plan-alert',
      });
      const isVisible = Components.useIsVisible();

      if (!isVisible) return null;

      return (
        <Components.Root className={clsx(className, classes?.root)}>
          <Components.Content className={classes?.content}>
            <Components.Aside className={classes?.aside}>
              <OtpNotEnabledAlertIcon />
            </Components.Aside>

            <Components.Text className={classes?.text}>
              {t('description')} {children}
            </Components.Text>
          </Components.Content>
        </Components.Root>
      );
    },
  {
    useIsVisible: () => useUnit($$session.$$jwt.$$company.$isLimitsExceeded),

    Root: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.root, className)} {...props} />
    ),

    Aside: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.aside, className)} {...props} />
    ),

    Content: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.content, className)} {...props} />
    ),

    Text: ({ className, ...props }: Partial<TypographyProps>) => (
      <Typography
        className={clsx(styles.text, className)}
        variant='small3'
        {...props}
      />
    ),
  }
);

export { ExceedLimitsOfIndividualPlanAlert };
