enum PushNotificationGroup {
  All = 'All',
  Login = 'Login',
  Invoice = 'Invoice',
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal',
  AutoConvert = 'AutoConvert',
  Payout = 'Payout',
  Referral = 'Referral',
  Identification = 'Identification',
  ManualConvert = 'ManualConvert',
}

enum PushNotificationTemplate {
  /**
   * User
   */
  SuccessfulLogin = 'SuccessfulLogin',

  /**
   * Invoice
   */
  InvoiceDepositProcessed = 'InvoiceDepositProcessed',
  InvoiceDepositSuspended = 'InvoiceDepositSuspended',
  InvoiceDepositArrested = 'InvoiceDepositArrested',
  InvoiceDepositCheckSuccessful = 'InvoiceDepositCheckSuccessful',
  InvoiceReminderWithHelp = 'InvoiceReminderWithHelp',
  InvoiceUnPaid = 'InvoiceUnPaid',

  /**
   * Withdrawal
   */
  WithdrawalProcessed = 'WithdrawalProcessed',

  /**
   * Deposit
   */
  DepositProcessed = 'DepositProcessed',
  CompanyExceededDepositLimits = 'CompanyExceededDepositLimits',

  /**
   * Payout
   */
  PayoutProcessed = 'PayoutProcessed',
  PayoutFailed = 'PayoutFailed',

  /**
   * AutoConvert
   */
  AutoConvertProcessed = 'AutoConvertProcessed',
  ManualConvertProcessed = 'ManualConvertProcessed',

  /**
   * Referral
   */
  ReferralRewardProcessed = 'ReferralRewardProcessed',
  ReferralReminderEndDiscount = 'ReferralReminderEndDiscount',

  /**
   * Identification
   */
  IdentificationAccepted = 'IdentificationAccepted',
  IdentificationRejected = 'IdentificationRejected',
  IdentificationDetailsAboutProduct = 'IdentificationDetailsAboutProduct',
  IdentificationNeedHelp = 'IdentificationNeedHelp',
  IdentificationNeedToComplete = 'IdentificationNeedToComplete',
}

export { PushNotificationGroup, PushNotificationTemplate };
