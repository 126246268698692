import { useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import { useTypedGate } from '@kuna-pay/core/shared/router';

import { useSearchParam } from '@kuna-pay/merchant/pages/merchant/create/lib';

import { VerifyOneTimeCode } from '../../abstract/verify-one-time-code';
import { ConfirmEmailModel } from './confirm-email.model';

type ConfirmEmailFormProps = {};

const ConfirmEmailForm = modelView(
  ConfirmEmailModel,
  (_: ConfirmEmailFormProps) => {
    const otp = useSearchParam('otp');
    const $$model = ConfirmEmailModel.useModel();

    useTypedGate({
      Gate: $$model.Gate,
      props: { i18n: useTranslation('core') },
    });

    return (
      <VerifyOneTimeCode.Form
        name='confirm-email-form'
        $$model={$$model.$$form}
        hideButton
        defaultValue={otp}
      />
    );
  }
);

export { ConfirmEmailForm };
export type { ConfirmEmailFormProps };
