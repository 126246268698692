import clsx from 'clsx';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch } from 'react-router-dom';

import { useMediaQuery } from '@kuna-pay/utils/react/use-media-query';
import { Navigate } from '@kuna-pay/ui/router';
import { Tabs } from '@kuna-pay/ui/ui/tabs';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { routes } from '@kuna-pay/merchant/shared/router';
import { MainLayout } from '@kuna-pay/merchant/widgets/layout';

import {
  AccountSettingsNavItem,
  APIKeySettingsNavItem,
  ExchangeCourseSettingsNavItem,
  PaymentsCustomizationSettingsNavItem,
  SettingsLayout,
} from './ui';
import styles from './page.module.scss';

const SettingsRootPage = memo(() => {
  const match = useMatch('/settings/:tab');
  const tab = match?.params?.tab;
  const { t } = useTranslation();
  const isTabletOrLess = useMediaQuery('(max-width: 1024px)');
  const { isVisible: isMFAAlertVisible } =
    MainLayout.MFANotEnabledAlert.useIsVisible();

  const isExceedLimitAlertVisible = MainLayout.ExceedLimitAlert.useIsVisible();

  if (!tab) {
    return isTabletOrLess ? (
      <MobileSettingsRootPage />
    ) : (
      <Navigate to={routes.settings.account} replace />
    );
  }

  return (
    <SettingsLayout>
      {!isTabletOrLess && (
        <>
          <Typography
            className={clsx(styles.title, {
              [styles.alert]: isMFAAlertVisible || isExceedLimitAlertVisible,
            })}
            variant='h6'
          >
            {t('pages.settings.host.title')}
          </Typography>

          <MainLayout.AlertGroup>
            <MainLayout.ExceedLimitAlert />

            <MainLayout.MFANotEnabledAlert />
          </MainLayout.AlertGroup>
        </>
      )}

      <Tabs value={tab} variant='linear'>
        {!isTabletOrLess && (
          <Tabs.List className={styles.tabList}>
            <AccountSettingsNavItem.Tab />

            <APIKeySettingsNavItem.Tab />

            <PaymentsCustomizationSettingsNavItem.Tab />

            <ExchangeCourseSettingsNavItem.Tab />
          </Tabs.List>
        )}

        {/* Wrapping <Outlet/> into Tabs doesn't create any styling issues */}
        <Outlet />
      </Tabs>
    </SettingsLayout>
  );
});

const MobileSettingsRootPage = memo(() => {
  const { t } = useTranslation();

  return (
    <SettingsLayout>
      <Typography className={styles.title} variant='h6'>
        {t('pages.settings.host.title')}
      </Typography>

      <div className={styles.navigation}>
        <AccountSettingsNavItem.NavButton />

        <APIKeySettingsNavItem.NavButton />

        <PaymentsCustomizationSettingsNavItem.NavButton />

        <ExchangeCourseSettingsNavItem.NavButton />
      </div>
    </SettingsLayout>
  );
});

export { SettingsRootPage };
export default SettingsRootPage;
