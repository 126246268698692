/* eslint-disable padding-line-between-statements */
import { memo } from 'react';

import {
  AnyGroupPushNotification24Icon,
  AuthGroupPushNotification24Icon,
  AutoConvertGroupPushNotification24Icon,
  DepositsGroupPushNotification24Icon,
  InvoicesGroupPushNotification24Icon,
  NewsGroupPushNotification24Icon,
  PayoutsGroupPushNotification24Icon,
  ReferralsGroupPushNotification24Icon,
  VerificationGroupPushNotification24Icon,
  WithdrawalsGroupPushNotification24Icon,
} from '../../assets';
import { PushNotificationGroup } from '../../types';

type PushNotificationGroupIconProps = {
  group: string;
};

const PushNotificationGroup24Icon = Object.assign(
  memo(({ group }: PushNotificationGroupIconProps) => {
    switch (group) {
      case PushNotificationGroup.Login: {
        return <AuthGroupPushNotification24Icon />;
      }

      case PushNotificationGroup.Invoice: {
        return <InvoicesGroupPushNotification24Icon />;
      }

      case PushNotificationGroup.Payout: {
        return <PayoutsGroupPushNotification24Icon />;
      }

      case PushNotificationGroup.Referral: {
        return <ReferralsGroupPushNotification24Icon />;
      }

      case PushNotificationGroup.Identification: {
        return <VerificationGroupPushNotification24Icon />;
      }

      case PushNotificationGroup.Withdrawal: {
        return <WithdrawalsGroupPushNotification24Icon />;
      }

      case PushNotificationGroup.Deposit: {
        return <DepositsGroupPushNotification24Icon />;
      }

      case PushNotificationGroup.ManualConvert:
      case PushNotificationGroup.AutoConvert: {
        return <AutoConvertGroupPushNotification24Icon />;
      }

      case 'News': {
        return <NewsGroupPushNotification24Icon />;
      }

      default: {
        return <AnyGroupPushNotification24Icon />;
      }
    }
  }),
  {
    All: AnyGroupPushNotification24Icon,
  }
);

export { PushNotificationGroup24Icon };
