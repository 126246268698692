import { Rate } from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const findManyRateRequest = (
  select: Select<Rate> | string
) => `query findManyRate {
  data: findManyRate {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'Rate')}`;
export const findManyRate = (select: Select<Rate> | string) => () =>
  query<Rate[]>(findManyRateRequest(select));
