import clsx from 'clsx';
import { forwardRef, type HTMLAttributes } from 'react';

import styles from './alert-group.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {};

export const AlertGroup = forwardRef<HTMLDivElement, Props>((props: Props) => {
  const { className, ...restProps } = props;

  return <div className={clsx(styles.alertGroup, className)} {...restProps} />;
});
