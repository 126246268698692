import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { MFAActivatedPage } from './activated';
import { AskToSetupAuthenticator2FA } from './ask';
import { ChooseAuthenticatorForSetup2FAPage } from './choose';
import { TurnOn2FASMSPage } from './sms';
import { TurnOn2FAAuthenticatorPage } from './turn-on';

const MFAPages: RouteObject[] = [
  {
    path: '/2fa/*',
    children: [
      {
        path: 'turn-on',
        Component: TurnOn2FAAuthenticatorPage.Component,
      },
      {
        path: 'activated',
        Component: MFAActivatedPage.Component,
      },
      {
        path: 'ask',
        Component: AskToSetupAuthenticator2FA.Component,
      },
      {
        path: 'choose',
        Component: ChooseAuthenticatorForSetup2FAPage.Component,
      },
      {
        path: 'sms',
        Component: TurnOn2FASMSPage.Component,
      },
      {
        path: '*',
        Component: () => <Navigate to='ask' replace />,
      },
    ],
  },
];

export { MFAPages };
