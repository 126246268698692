import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { JWTCompanyRole } from '@kuna-pay/core/entities/session';

import {
  CanAccess,
  VerificationGuard,
} from '@kuna-pay/merchant/entities/session';
import { InvoiceTutorialVideo } from '@kuna-pay/merchant/entities/tutorial/ui';
import { DiscoveryNotificationPaymentSettingsBanner } from '@kuna-pay/merchant/pages/invoices/ui';
import { MerchantLayout } from '@kuna-pay/merchant/widgets/layout/ui';

const InvoicesRootPage = memo(() => {
  const { t } = useTranslation();

  return (
    <VerificationGuard
      from='Invoices'
      Layout={MerchantLayout.Content}
      skipVerifyButton
      pending={
        <VerificationGuard.PendingContainer>
          <InvoiceTutorialVideo className={VerificationGuard.videoClassName} />

          <VerificationGuard.PendingTitle variant='h9' />

          <VerificationGuard.Description>
            {t('pages.invoices.root.unverified.description-2')}
          </VerificationGuard.Description>
        </VerificationGuard.PendingContainer>
      }
      unverified={
        <>
          <InvoiceTutorialVideo className={VerificationGuard.videoClassName} />

          <VerificationGuard.VideoTitle>
            {t('pages.invoices.root.unverified.title')}
          </VerificationGuard.VideoTitle>

          <VerificationGuard.Description>
            {t('pages.invoices.root.unverified.description')}
          </VerificationGuard.Description>

          <VerificationGuard.VerifyButton from='Invoices' />
        </>
      }
    >
      <Outlet />

      <CanAccess.Company.Role
        role={[JWTCompanyRole.Admin, JWTCompanyRole.Owner]}
      >
        <DiscoveryNotificationPaymentSettingsBanner />
      </CanAccess.Company.Role>
    </VerificationGuard>
  );
});

export default InvoicesRootPage;
