import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

const STORAGE_KEY = 'config-close-banner';

export type StorageValue = {
  singUp: boolean;
};

const defaultValues: StorageValue = {
  singUp: true,
};

export const useCanShowWarningBanner = () => {
  const [state, setState] = useState(defaultValues);

  const onHideBanner = useCallback((key: keyof StorageValue) => {
    setState((prevState) => {
      const newState = {
        ...prevState,
        [key]: false,
      };

      localStorage.setItem(STORAGE_KEY, JSON.stringify(newState));

      return newState;
    });
  }, []);

  useEffect(() => {
    console.log(state);
  }, [state]);

  useLayoutEffect(() => {
    const persistedValue = localStorage.getItem(STORAGE_KEY);

    if (persistedValue) {
      setState(JSON.parse(persistedValue));
    } else {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(defaultValues));
    }
  }, []);

  return {
    bannerState: state,
    onHideBanner,
  };
};
