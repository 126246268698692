import { combine, createEvent } from 'effector';

import { listen } from '@kuna-pay/utils/effector';
import { atom, bridge } from '@kuna-pay/utils/misc';
import { createEQuery } from '@kuna-pay/core/shared/lib/effector-query';

import type { Rate } from '@kuna-pay/merchant/generated/graphql';
import { findManyRate } from '@kuna-pay/merchant/shared/api/generated/Rate/request/findManyRate';
import {
  createWebsocketSubscription,
  WS_EVENTS,
} from '@kuna-pay/merchant/shared/api/ws';

import type { ExchangeRateEquivalents, ExchangeRates } from '../types';

const $$exchangeRates = atom(() => {
  const load = createEvent();
  const stop = createEvent();

  const $$exchangeRatesQuery = createEQuery({
    initialData: [] as Rate[],

    query: (_: void) =>
      findManyRate({
        currency: true,
        equivalent: {
          amount: true,
          currency: true,
        },
      })(),
  });

  listen({
    clock: load,
    handler: () => {
      void $$exchangeRatesQuery.startFx();
    },
  });

  bridge(() => {
    const $$subscription = createWebsocketSubscription({
      channel: WS_EVENTS.rates.channel,
      contract: WS_EVENTS.rates.contract,

      start: load,
      stop: stop,
    });

    /**
     * Assumption: the server will always send the latest rates for *all* currencies
     * So we can just replace the old rates with the new ones
     */
    $$exchangeRatesQuery.$data.on(
      $$subscription.doneValidated,
      (_rates, { data: newRates }) => newRates
    );
  });

  const $ratesMap = combine($$exchangeRatesQuery.$data, (rates) =>
    rates.reduce((kv, rate) => {
      kv[rate.currency] = {
        ...rate,
        equivalent: rate.equivalent.reduce((map, equivalent) => {
          map[equivalent.currency] = equivalent;

          return map;
        }, {} as ExchangeRateEquivalents),
      };

      return kv;
    }, {} as ExchangeRates)
  );

  return {
    load,
    stop,
    loaded: $$exchangeRatesQuery.finished.done,
    $pending: $$exchangeRatesQuery.$isLoading,

    $$ui: {
      $ratesMap,
    },
  };
});

export { $$exchangeRates };
