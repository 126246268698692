import { createGate } from 'effector-react';

import { listen, modelFactory } from '@kuna-pay/utils/effector';
import { createSingleModal } from '@kuna-pay/ui/ui/modal';

const STORAGE_KEY = 'close-warning-modal';

type StorageValue = {
  isHidden: boolean;
};

const defaultValue: StorageValue = {
  isHidden: false,
};

export const StopWorkingWarningModel = modelFactory(() => {
  const Gate = createGate();

  const $$modal = createSingleModal();

  listen({
    clock: Gate.open,
    handler: () => {
      const persistedValue = sessionStorage.getItem(STORAGE_KEY);

      if (persistedValue) {
        const isHidden = (JSON.parse(persistedValue) as StorageValue).isHidden;

        if (!isHidden) {
          $$modal.open();
        }
      } else {
        $$modal.open();
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(defaultValue));
      }
    },
  });

  listen({
    clock: $$modal.closed,
    handler: () => {
      sessionStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
          isHidden: true,
        })
      );
    },
  });

  return {
    $$ui: {
      Gate,
      $$modal,
    },
  };
});
