import type { Numeric } from 'decimal.js-light';
import Decimal from 'decimal.js-light';

import type { CoreAsset } from '@kuna-pay/core/entities/asset';

import type {
  CompanyAsset,
  Equivalent,
  PublicAssetOutput,
} from '@kuna-pay/merchant/generated/graphql';

import type { ExchangeRates } from '../types';

class ExchangeRateConverter {
  public static convertAssetByExchangeRate = (args: {
    amount: Numeric;
    rate: Equivalent;
  }) => {
    const amountAsDecimal = new Decimal(args.amount);

    return amountAsDecimal.mul(args.rate.amount);
  };

  public static getExchangeRate = (args: {
    fromAssetCode: PublicAssetOutput['code'];
    toAssetCode: PublicAssetOutput['code'];
    ratesMap: ExchangeRates;
  }) => {
    const ratesForAsset = args.ratesMap[args.fromAssetCode];

    if (!ratesForAsset) {
      return null;
    }

    const rateEquivalent = ratesForAsset.equivalent[args.toAssetCode];

    if (rateEquivalent) {
      return rateEquivalent;
    }

    const ratesForToAsset = args.ratesMap[args.toAssetCode];

    if (!ratesForToAsset) {
      return null;
    }

    const rateToFrom = ratesForToAsset.equivalent[args.fromAssetCode];

    if (!rateToFrom) {
      return null;
    }

    if (rateToFrom.amount === '0') {
      return null;
    }

    return {
      ...rateToFrom,
      amount: new Decimal(1).div(rateToFrom.amount).toString(),
    };
  };

  public static findEquivalentAmount = (args: {
    fromAssetCode: CoreAsset['code'];
    amount: CompanyAsset['balance'];
    toAssetCode: CoreAsset['code'];
    rates: ExchangeRates;
  }): Decimal | null => {
    const rate = ExchangeRateConverter.getExchangeRate({
      fromAssetCode: args.fromAssetCode,
      toAssetCode: args.toAssetCode,
      ratesMap: args.rates,
    });

    if (!rate) {
      return null;
    }

    return ExchangeRateConverter.convertAssetByExchangeRate({
      amount: args.amount,
      rate,
    });
  };
}

export { ExchangeRateConverter };
