import type { CoreAsset } from '@kuna-pay/core/entities/asset';

import { AssetType } from '@kuna-pay/merchant/generated/graphql';

const ASSET_COLORS: Record<string, string[]> = {
  EUR: ['#4AEAB1', '#16C67C'],
  BTC: ['#FFD420', '#FFA000'],
  ETH: ['#3BADEF', '#2468E4'],
  BCH: ['#1BEC73', '#00BA4F'],
  UAH: ['#B2FFFF', '#41C8FC'],
  XRP: ['#F1F2F3', '#D5D7DD'],
  LTC: ['#E7E7E7', '#A4A4A4'],
  KUN: ['#7BA4F3', '#2E71F0'],
  DRK: ['#2477E3', '#00439B'],
  EVER: ['#8B76F8', '#6347F5'],
  USDC: ['#5BA1ED', '#2775CA'],
  EOS: ['#F1F2F3', '#D5D7DD'],
  SHIB: ['#FBA846', '#F25656'],
  USDT: ['#73E470', '#4FAA4D'],
  ETHW: ['#C0C6F2', '#748DD5'],
  TRX: ['#FFB9B5', '#FF8178'],
  DOGE: ['#EEDA8E', '#B29B46'],
  WAVES: ['#7BA4F3', '#002FFF'],
  XLM: ['#B5BCC0', '#5B6A72'],
  ZEC: ['#FFE49F', '#F6C350'],
};

const ASSET_FALLBACK_COLOR = '#F7F8FB';

const EUR_ASSET: CoreAsset = {
  code: 'EUR',
  name: 'EUR',
  precision: 2,
  type: AssetType.Fiat,
  position: 1,
  payload: {
    icons: {
      svg: 'https://kuna-pro.beta.anuk.tech/icon-eur-svg',
      png2x: 'https://kuna-pro.beta.anuk.tech/icon-eur-png2x',
      png3x: 'https://kuna-pro.beta.anuk.tech/icon-eur-png3x',
      svgXL: 'https://kuna-pro.beta.anuk.tech/icon-eur-svg',
    },
  },
};

export { ASSET_COLORS, ASSET_FALLBACK_COLOR, EUR_ASSET };
