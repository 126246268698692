import { memo } from 'react';

import { Typography } from '@kuna-pay/ui/ui/typography';
import type { CoreAsset } from '@kuna-pay/core/entities/asset';
import { Currency } from '@kuna-pay/core/entities/asset';

import styles from './asset.column.module.scss';

type AssetColumnProps = {
  Asset: CoreAsset;
};

const AssetColumn = {
  Horizontal: memo(({ Asset }: AssetColumnProps) => (
    <Currency asset={Asset} showCode />
  )),

  Vertical: memo(({ Asset }: AssetColumnProps) => (
    <Currency.Root className={styles.verticalRoot}>
      <Currency.Icon asset={Asset} size={24} />

      <div>
        <Typography variant='subtitle5' nowrap>
          {Asset.name}
        </Typography>

        <Typography className='text-black500' variant='numbers2' nowrap>
          {Asset.code}
        </Typography>
      </div>
    </Currency.Root>
  )),
};

export { AssetColumn };
