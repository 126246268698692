import type { PropsWithChildren } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { VerificationGuard } from '@kuna-pay/merchant/entities/session';
import { AutoConvertTutorialVideo } from '@kuna-pay/merchant/entities/tutorial/ui';
import { MainLayout } from '@kuna-pay/merchant/widgets/layout';

import styles from './layout.module.scss';

const SettingsLayout = memo(({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <MainLayout.Merchant.Content className={styles.root}>
      <VerificationGuard
        from='Settings'
        skipVerifyButton
        pending={
          <VerificationGuard.PendingContainer>
            <AutoConvertTutorialVideo
              className={VerificationGuard.videoClassName}
            />

            <VerificationGuard.PendingTitle variant='h9' />

            <VerificationGuard.Description>
              {t(
                'pages.settings.host.unverified.auto-convert.description-pending'
              )}
            </VerificationGuard.Description>
          </VerificationGuard.PendingContainer>
        }
        unverified={
          <>
            <AutoConvertTutorialVideo
              className={VerificationGuard.videoClassName}
            />

            <VerificationGuard.VideoTitle>
              {t('pages.settings.host.unverified.auto-convert.title')}
            </VerificationGuard.VideoTitle>

            <VerificationGuard.Description>
              {t('pages.settings.host.unverified.auto-convert.description')}
            </VerificationGuard.Description>

            <VerificationGuard.VerifyButton from='Settings' />
          </>
        }
      >
        {children}
      </VerificationGuard>
    </MainLayout.Merchant.Content>
  );
});

export { SettingsLayout };
