import clsx from 'clsx';
import { type HTMLAttributes, memo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Link } from '@kuna-pay/ui/router';
import { Button } from '@kuna-pay/ui/ui/button';
import { Paper } from '@kuna-pay/ui/ui/paper';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { ReactComponent as CrossIcon } from '../assets/cross.svg';
import { ReactComponent as WarningIcon } from '../assets/warning.svg';
import { type StorageValue } from './hooks/use-can-show-warning-banner';
import styles from './stop-working-warning.banner.module.scss';

type Props = {
  areaKey: keyof StorageValue;
  onHideBanner: (key: keyof StorageValue) => void;
} & Omit<HTMLAttributes<HTMLDivElement>, 'children'>;

export const StopWorkingWarningBanner = memo((props: Props) => {
  const { className, areaKey, onHideBanner, ...restProps } = props;

  const { t } = useTranslation('core');

  const onClose = useCallback(() => {
    onHideBanner(areaKey);
  }, [areaKey]);

  return (
    <Paper className={clsx(styles.root, className)} {...restProps}>
      <Button
        color='primary'
        variant='text'
        size='sm'
        className={styles.closeBtn}
        onClick={onClose}
      >
        <CrossIcon />
      </Button>
      <div className={styles.head}>
        <span className={styles.iconWrapper}>
          <WarningIcon />
        </span>
        <Typography variant='h7'>
          {t('features.close-warning.banner.title')}
        </Typography>
      </div>
      <div className={styles.content}>
        <div className={styles.description}>
          <Typography variant='numbers2'>
            {t('features.close-warning.banner.description.text-1')}
          </Typography>
          <ul className={styles.list}>
            <li>
              <Typography variant='numbers2'>
                <Trans
                  t={t}
                  i18nKey='features.close-warning.banner.description.point-1'
                  components={{
                    highlight: <Typography variant='numbers1' as='span' />,
                  }}
                />
              </Typography>
            </li>
            <li>
              <Typography variant='numbers2'>
                <Trans
                  t={t}
                  i18nKey='features.close-warning.banner.description.point-2'
                  components={{
                    highlight: <Typography variant='numbers1' as='span' />,
                  }}
                />
              </Typography>
            </li>
            <li>
              <Typography variant='numbers2'>
                <Trans
                  t={t}
                  i18nKey='features.close-warning.banner.description.point-3'
                  components={{
                    highlight: <Typography variant='numbers1' as='span' />,
                  }}
                />
              </Typography>
            </li>
            <li>
              <Typography variant='numbers2'>
                <Trans
                  t={t}
                  i18nKey='features.close-warning.banner.description.point-4'
                  components={{
                    highlight: <Typography variant='numbers1' as='span' />,
                  }}
                />
              </Typography>
            </li>
          </ul>
          <Typography variant='numbers2'>
            <Trans
              t={t}
              i18nKey='features.close-warning.banner.description.text-2'
              components={{
                highlight: <Typography variant='numbers1' as='span' />,
              }}
            />
          </Typography>
          <Typography variant='numbers2'>
            {t('features.close-warning.banner.description.text-3')}
          </Typography>
        </div>
        <div className={styles.contact}>
          <Link to='mailito:support@kunapay.io' target='_blank'>
            <Typography variant='subtitle5_1'>support@kunapay.io</Typography>
          </Link>
        </div>
      </div>
    </Paper>
  );
});
